@font-face {
  font-family: "musicnet";
  src: local("musicnet"), url("./fonts/musicnet.ttf");
}
body,
html,
* {
  color: black;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.coming-soon-page {
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 80vw;
  height: 80vw;
  max-width: 450px;
  max-height: 450px;
  border-radius: 50%;
  background-color: #00fe70;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

.logo {
  width: 75%;
}

.visualization-animation {
  font-size: clamp(1rem, 4.333vw, 3.6rem);
  letter-spacing: 2px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.contact {
  font-size: 20px;
  font-weight: 700;
}

.email {
  font-size: clamp(1rem, 4vw, 3.6rem);
}

.instagram {
  margin: 10px;
  font-size: clamp(1rem, 8vw, 3.6rem);
}

.coming-soon {
  color: #00fe70;
  margin-top: clamp(1rem, 10vw, 4rem);
  font-family: "musicnet";
  font-size: clamp(1rem, 10vw, 5rem);
}

@media only screen and (min-width: 500px) {
  .visualization-animation {
    font-size: 24px;
  }
  .instagram {
    margin: 20px;
    font-size: 40px;
  }
  .email {
    font-size: 20px;
  }
  .coming-soon {
    font-size: clamp(1rem, 8vw, 5rem);
  }
}

@media only screen and (min-width: 768px) {
  .circle {
    width: 500px;
    height: 500px;
  }
  .visualization-animation {
    font-size: 24px;
  }
  .coming-soon {
    font-size: 54px;
  }
}
